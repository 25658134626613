import React, { Component } from "react";
import "./App.css";
import logo from "./logo-white.png";

class App extends Component {
  public componentDidMount() {
      if (typeof window !== "undefined" && window.location.pathname.substr(0, 6) === "/login") {
           window.location.href = "tgg://" + window.location.pathname;
      }
  }

  public render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>
            Download our app
          </h1>
        </header>
        <main className="App-main">
            <a href="https://apps.apple.com/gb/app/pinter/id1556013005" className="App-link">
              <i className="fab fa-apple App-link-icon"></i>
              <div>
                <span className="App-span">Available on the</span>
                App Store
              </div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.thepinterapp" className="App-link">
            <i class="fab fa-google-play App-link-icon"></i>
              <div>
                <span className="App-span">Get it on</span>
                Google Play
              </div>
            </a>
        </main>
      </div>
    );
  }
}

export default App;
